<template>
	<div class="modal_info modal_show_mission_vision">
		<template v-if="loaded">
			<template v-if="company.target">
				<div class="header-submodal">
					<div class="title">{{ $t('company.mision_vision_title') }}</div>
				</div>

				<div class="description-modal">
					<div class="label">{{ $t('company.target') }}</div>
					<div class="value">{{ company.target }}</div>
				</div>
				<div class="description-modal">
					<div class="label">{{ $t('company.mission') }}</div>
					<div class="value" v-html="company.mission"></div>
				</div>
			</template>
			<template v-else>
				<div class="header-submodal">
					<div class="title">{{ $t('company.not_completed') }}</div>
				</div>
				<div class="description-modal" >
					<div class="value">{{ $store.getters['applications/getApplication']('Organigrama') && $store.getters['applications/getApplication']('Organigrama').is_active ? $t('company.only_for_admins') : $t('company.organigrame_soft_missing') }}</div>
				</div>
			</template>

			<div class="button-actions">
				<button class="btn-tbf center blue" @click="closeModal">
					<div class="text">{{ $t('general.close') }}</div>
				</button>
			</div>
		</template>
		<loader-modal-mission-vision v-else />
	</div>
</template>

<script type="text/javascript">
	import IconClose from '../Icons/Close'
	import LoaderModalMissionVision from '../PagesLoaders/ModalMissionVision'

	export default {
		data() {
			return {
				loaded: false,
				company: {}
			};
		},
        components: {
        	IconClose,
        	LoaderModalMissionVision
        },
		mounted() {
			if(this.$store.getters['applications/getApplication']('Organigrama') && this.$store.getters['applications/getApplication']('Organigrama').is_active) {
				this.getCompanyData()
			} else {
				this.loaded = true;
			}
		},
		methods: {
			getCompanyData() {
				axios.get(`${process.env.VUE_APP_ORGANIGRAM_LINK}/api/brand/${this.$auth.user().master_instance_id}/show`)
				.then(({data}) => {
					this.company = data.data
				})
				.finally(() => {
					this.loaded = true
				})
			},
			closeModal(){
				this.$emit("close_modal");
			}
		}
	};
</script>