<template>
	<div class="container-modal">
		<div class="header-modal">
			<div class="tabs-title" v-if="$resize && $mq.above(660)">
				<div class="tab-item" @click="activateTab('updates')">
					<span>{{ $t('general.updates') }}</span>
				</div>
				<div class="tab-item active" @click="activateTab('promises')">
					<span>{{ $t('general.promises') }}</span>
				</div>
				<div class="tab-item" @click="activateTab('tasks')">
					<span>{{ $t('general.tasks') }}</span>
				</div>
				<div class="tab-item" @click="activateTab('comments')">
					<span>{{ $t('key-result-comments.title') }}</span>
				</div>
				<div class="tab-item">
					<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 0, hide: 0}">
						<icon-info />

						<template slot="popover">
							<div class="kr-title">{{ keyResult.name }}</div>
							<div class="kr-description">{{ keyResult.description }}</div>

							<div class="kr-info">
								<div class="label">{{ $t('key-result-summary.progress-type') }}</div>
								<div class="label-desc">{{ $t('progress_type_text_simple.' + keyResult.progress_type, { to: ($options.filters.numeral(parseFloat(keyResult.target), '0a')), unit: keyResult.unity, from: $options.filters.numeral(parseFloat(keyResult.start_value), '0a')}) }}</div>
							</div>

							<div class="kr-info">
								<div class="label">{{ $t('key-result-summary.frequency-interval') }}</div>
								<div class="label-desc">{{ $t('key-result-updates.interval-update-desc') }} {{ $tc('days', keyResult.measurement) }}</div>
							</div>

							<div class="kr-info">
								<div class="label">{{ $t('key-result-summary.period') }}</div>
								<div class="label-desc"><span class="date">{{ keyResult.start_date | moment('DD MMM') }}</span> {{ $t('key-result-summary.period-to') }} <span class="date">{{ keyResult.end_date | moment('DD MMM')}}</span></div>
							</div>
						</template>
					</v-popover>
				</div>
			</div>
			<div class="mobile-tabs" v-else>
				<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					{{ $t('general.promises') }}
					<icon-arrow class="icon-arrow"/>
				</button>
				<div class="dropdown-menu" aria-labelledby="dropdownFilter">
					<div class="dropdown-item" @click="activateTab('updates')">{{ $t('general.updates') }}</div>
					<div class="dropdown-item" @click="activateTab('promises')">{{ $t('general.promises') }}</div>
					<div class="dropdown-item" @click="activateTab('tasks')">{{ $t('general.tasks') }}</div>
					<div class="dropdown-item" @click="activateTab('comments')">{{ $t('key-result-comments.title') }}</div>
				</div>
			</div>

			<div class="actions">
				<!-- <v-popover offset="5" trigger="click" placement="bottom" :disabled="need_promise && keyResult.status != 'upcoming'" popoverBaseClass="popover-tbf" class="btn-space-mr" :delay="{show: 0, hide: 0}" v-if="$route.name != 'public-master-goal' && keyResult.rights.can_create">
					<div class="btn-blue-tbf add-btn" @click="need_promise && keyResult.status != 'upcoming' ? showAddPromise() : ''"><icon-plus class="icon-plus" /></div>

					<template slot="popover">
						<div class="simple-text" v-if="keyResult.status == 'upcoming'">{{ $t('key-result.tooltip-promises-cant') }}</div>
						<div class="simple-text" v-else-if="today_promise">{{ $t('key-result.promises.already_set') }}</div>
						<div class="simple-text" v-else>{{ $t('key-result.promises.no-need-promise-today') }}</div>
					</template>
				</v-popover> -->
				<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" class="btn-space-mr" :delay="{show: 500, hide: 0}"  v-if="$route.name != 'public-master-goal' && keyResult.rights.can_create && parseInt(keyResult.percent) >= 100">
					<button class="btn-default-tbf finish-kr" v-bind:class="{finished: keyResult.status == 'finished'}" @click="completeKr"><icon-check /></button>

					<template slot="popover">
						<div class="simple-text">{{ keyResult.status == 'finished' ? $t('key-result.tooltip-not-finish-kr') : $t('key-result.tooltip-finish-kr') }}</div>
					</template>
				</v-popover>

				<div class="btn-default-tbf close-btn" @click="closeModal"><icon-plus class="icon-close" /></div>
			</div>
		</div>

		<div class="info-content">
			<div class="container-content-modal opacity-page" v-if="loaded">
				<div class="list-tbf list-promises" v-if="viewCreatePromise">
					<div class="item-promise create-promise">
						<div class="icon-promise" v-if="$resize && $mq.above(660)">
							<img src="/build/icons/promise-pending.svg">
						</div>
						
						<div class="details-promise">
							<div class="data-promise">
								<div class="data">
									<div class="title-input">{{ $t('key-result.promises.today_promise') }} - {{ moment().format('DD MMMM, YYYY')  }}</div>
									<div class="textarea-promise">
										<textarea class="input-textarea" :placeholder="$t('daily_promise.title-input-placeholder')" v-model="promise_day.description" v-autosize rows="1"></textarea>
									</div>
								</div>
								<div class="btns-actions edit-actions">
									<button class="btn-default-tbf btn-save" @click="storePromise">{{ $t('key-result.promises.set_promise') }}</button>
									<button class="btn-delete" @click="viewCreatePromise = false"><icon-delete /></button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="list-tbf list-promises" v-if="promises.length > 0">
					<div class="top-description" v-html="$tc('key-result.promises.top-promises-description-first', needs_to_be_evaluated.length)+'. '+$tc('key-result.promises.top-promises-description-second', promises.length)"></div>
					<div class="item-tbf item-promise" v-for="promise in promises" v-bind:class="{pending: promise.status == 'pending'}">
						<div class="icon-promise" v-if="$resize && $mq.above(660)">
							<img :src="'/build/icons/promise-' + promise.status + '.svg'">
						</div>
						<div class="details-promise">
							<div class="data-promise">
								<div class="data">
									<div class="date" v-if="promise.status != 'pending'">{{ $t('timeline_status.' + promise.status) }} {{ promise.resolved_at ? '- '  + moment(promise.resolved_at).format('DD MMMM, YYYY') : '' }}</div>
									<div class="question-promise" v-else>{{ $t('set-promise.question_ev') }} </div>
									<div class="title">{{ promise.name }}</div>
								</div>
								<div class="icon-status" v-if="promise.status != 'pending'">
									<img src="/build/icons/circle-check.svg" v-if="promise.status == 'completed' && ($resize && $mq.above(660))">
									<img src="/build/icons/circle-check-pending.svg" v-else-if="promise.status == 'pending' && ($resize && $mq.above(660))">
									<img src="/build/icons/circle-error.svg" v-else-if="promise.status == 'not_completed' && ($resize && $mq.above(660))">
								</div>
								<div class="btns-actions" v-else-if="$route.name != 'public-master-goal' && promise.rights.edit">
									<button class="button-tbf-blue" @click="showEvaluate(promise)">
										{{ $t('daily_promise.evaluate') }}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="submodal-container" v-if="subModalShow">
					<div class="overlay-submodal"></div>
				</div>
				
				<div class="empty-box" v-else-if="!viewCreatePromise && promises.length == 0">
					<div class="empty-row">
						<div class="title">
							{{ keyResult.status == 'upcoming' ? $t('key-result.tooltip-promises-cant') : $t('key-result.no-promises') }}
						</div>
						<div class="description" v-html="$t('key-result.no-promises-description')">
						</div>
					</div>
				</div>
			</div>
			<div class="container-content-modal" v-else>
				<div class="list-tbf list-promises">
					<div class="item-tbf item-promise" v-for="n in 3">
						<div class="icon-promise" v-if="$resize && $mq.above(660)">
							<div class="placeholder-loader" style="height: 46px; width: 46px;"></div>
						</div>
						<div class="details-promise">
							<div class="data-promise">
								<div class="data" style="width: 100%;">
									<div class="date placeholder-loader" style="height: 16px; width: 100%; max-width: 300px; display: inline-block;"></div>
									<div class="title placeholder-loader" style="height: 21px; width: 100%; max-width: 350px;"></div>
								</div>
								<div class="icon-status">
									<div class="placeholder-loader user" style="height: 18px; width: 18px;"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconPlus from "../Icons/Plus"
	import IconCheck from "../Icons/Check"
	import IconArrow from "../Icons/Arrow"
	import IconDelete from "../Icons/Delete"
	import IconInfo from "../Icons/Info"
	import IconKeyResults from "../Icons/KeyResults"
	import IconList from '../Icons/List'
	import { required, decimal } from 'vuelidate/lib/validators'

	export default {
		data() {
			return {
				loaded: false,
				viewCreatePromise: false,
				promises: [],
				completed_promises: 0,
				needs_to_be_evaluated: 0,
				promise_day: {
					description: ''
				},
				need_promise: false,
				today_promise: false,
				subModalShow: false
			};
		},
		props:{
			keyResult: Object,
			Uuid: String
		},
		components: {
			IconPlus,
			IconCheck,
			IconDelete,
			IconArrow,
			IconInfo,
			IconList,
			IconKeyResults
		},
		validations: {
			promise_day: {
				description: {required}
			}
		},
		async mounted() {
			if(this.$route.name != 'public-master-goal'){
				await this.todayPromise()
			}
			
			await this.getPromises()
			this.$root.$on("refreshKeyResultPromises", () => {
 				this.subModalShow = false;
 				$('.submodal-container').removeClass('active');
				this.getPromises()
            });

			this.$root.$on("visibilityHiddenKeyResultPromises", (is_active) => {
				this.subModalShow = is_active;
				setTimeout(() => {
					if(is_active){
						$('.submodal-container').addClass('active');
					}else{
						$('.submodal-container').removeClass('active');					
					}
				}, 0)
			});

		},
		methods: {
			async todayPromise(){
				await axios.get('/today-promise').then(({data}) => {
					this.need_promise = data.data.need_promise_for_today
					this.today_promise = data.data.today_promise
				})
			},
			async getPromises(){
				await axios.get('/key-results/public/' + this.Uuid + '/promises')
				.then(({data}) => {
					this.promises = data.data
					this.needs_to_be_evaluated = this.promises.filter(el => el.status == 'pending')
				})
				.then(() => {
					setTimeout(()=>{
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 300)
				})
			},
			closeModal(){
				this.$emit("toggle_modal_tab");
			},
			showEvaluate(promise_obj){
				this.$root.$emit('evaluate_promise_modal', promise_obj, 'key_result_modals');
			},
			diffDays(end_date) {
				var a = moment().startOf('day');
				var b = moment(end_date).startOf('day');
				return b.diff(a, 'days');  
			},
			checkDiffUnity(unity){
				var inlineUnits = ['€', '$', 'RON']
				return inlineUnits.includes(unity)
			},
			activateTab(type){
				this.$emit("changeTab", type);
			},
			changeStatus(promise, status, e){
				axios.patch('/promises/'+ promise.id+'/evaluate', {
					accomplished: status,
					why_not: status ? '' : 'failed'
				}).then(({data}) => {
					$(e.target).attr('disabled', false)
					this.getPromises()
					this.$emit('refresh')
					this.$emit('refreshModalData')

					if(data.prize.length){
						this.$root.$emit('won_prize', data.prize.prize)
					}
				}).catch(error => {
					$(e.target).attr('disabled', false)
				});
			},
			showAddPromise(){
				this.viewCreatePromise = true
			},
			storePromise(e){
				$(e.target).attr('disabled', 'disabled')

				this.$v.$touch()
				if(!this.$v.$invalid){
					axios.post('/' + this.keyResult.id + '/promises/store',{
						key_result_id: this.keyResult.id,
						name: this.promise_day.description
					}).then(({data}) => {
						$(e.target).attr('disabled', false);
						this.todayPromise()
						this.getPromises()
						this.viewCreatePromise = false
						this.promise_day.description = ''
						this.$emit('refresh')
						this.$emit('refreshModalData')
					})

					$(e.target).attr('disabled', false)
				}else{
					$(e.target).attr('disabled', false)
				}
			},
			completeKr(e){
				$(e.target).attr('disabled', 'disabled')

				var statusKr = this.keyResult.status == 'finished' ? 'active' : 'finished'

				axios.post(`/key-results/${this.keyResult.id}/update-status`, { status: statusKr })
				.then(({data}) => {
					$(e.target).addClass('completed')
					setTimeout(() => {
						$(e.target).removeClass('completed')
						this.$emit('refreshModalData')
						this.$emit('refresh')
					}, 1500)
				})
				.finally(() => {
					setTimeout(() => {
						$(e.target).attr('disabled', false)
					}, 2000)
				})
			}
		}
	};
</script>