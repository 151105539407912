<template>
	<div class="template-tbfdigital">
        <template v-if="loadedApplications">
			<sidebar-tbf-digital v-if="$resize && $mq.above(780)" />
			<div class="main-tbfdigital">
				<navbar-tbf-digital/>
				<div class="page-content" :class="[$route.path.includes('master-goal/') ? 'full-width' : '']">
					<slot></slot>
				</div>

				<sidebar-tbf-digital-mobile v-if="$resize && $mq.below(780)"/>
			</div>
            <notifications/>

			<div class="overflow-modal" v-if="show_overflow">
				<div class="bg-modals" @click="closeModals()"></div>
				
				<modal-key-result id="modal_key_result" v-if="show_modal.key_result" :model="modelId.key_result" :requiredData="requiredData.key_result" :userData="userData.key_result" :fromPage="modalFromPage" @toggle_modal="closeModal('key_result')"/>

				<modal-key-result-modals id="modal_key_result_modals" v-if="show_modal.key_result_modals" :keyResult="modelId.key_result_modals" :uuid="krUuid" :activeTab="modalKeyTab" :fromPage="modalFromPage" @toggle_modal="closeModal('key_result_modals')"/>
				
				<modal-update-key-result id="modal_update_key_result" v-if="show_modal.update_key_result" :requiredData="requiredData.update_key_result" :userData="userData.update_key_result" :fromPage="modalFromPage" @toggle_modal="closeModal('update_key_result')"/>

				<modal-objective id="modal_objective" v-if="show_modal.objective" :model="modelId.objective" :requiredData="requiredData.objective"  @toggle_modal="closeModal('objective')" :fromPage="modalFromPage" canAddMasterGoalsInline="true" />
				
				<modal-master-goal id="modal_master_goal" v-if="show_modal.master_goal" :model="modelId.master_goal" :userData="userData.master_goal" :requiredData="requiredData.master_goal" :fromPage="modalFromPage" @toggle_modal="closeModal('master_goal')"/>
				
				<modal-user id="modal_user" v-if="show_modal.user" :model="modelId.user" @toggle_modal="closeModal('user')" :fromPage="modalFromPage" />

				<modal-tag id="modal_tag" v-if="show_modal.tag" :model="modelId.tag" @toggle_modal="closeModal('tag')" :fromPage="modalFromPage" />

				<modal-profile-settings id="modal_profile_settings" v-if="show_modal.profile_settings" @close_modal="closeModal('profile_settings')" />		
				<modal-notitifications id="modal_notifications" v-if="show_modal.notifications" @close_modal="closeModal('notifications')"/>

				<modal-delete id="modal_delete" v-if="show_modal.delete" :model="model.delete" :type="typeDelete" :from="fromDelete" @close_modal="closeModal('delete')" />

				<modal-add-promise id="modal_add_promise" v-if="show_modal.add_promise" @toggle_modal="closeModal('add_promise')"/>
				<modal-evaluate-promise id="modal_evaluate_promise" v-if="show_modal.evaluate_promise" @toggle_modal="closeModal('evaluate_promise')"  :model="evaluate_promise" :fromPage="modalFromPage"/>

				<modal-cant-create-more id="modal_cant_create_more" v-if="show_modal.cant_create_more" :type="typeDelete" @close_modal="closeModal('cant_create_more')" />
				<modal-cant-create-more-objective id="modal_cant_create_more_objective" v-if="show_modal.cant_create_more_objective" @close_modal="closeModal('cant_create_more_objective')" />

				<modal-required-instance id="modal_required_instance" v-if="show_modal.required_instance" @close_modal="closeModal('required_instance')"/>
				<modal-change-subscription id="modal_change_subscription" v-if="show_modal.change_subscription" @toggle_modal="closeModal('change_subscription')"/>
				<modal-payment-error id="modal_payment_error" v-if="show_modal.payment_error" @close_modal="closeModal('payment_error')"/>
				<modal-tutorial-promise id="modal_tutorial_promise" v-if="show_modal.tutorial_promise" @close_modal="closeModal('tutorial_promise')"/>
				<modal-congratulations id="modal_congratulations" v-if="show_modal.congratulations" @close_modal="closeModal('congratulations')"/>
				<modal-share-master-goal id="modal_share_master_goal" v-if="show_modal.share_master_goal" :model="modelId.share_master_goal" @close_modal="closeModal('share_master_goal')"/>

				<modal-cant-add-more-updates id="modal_cant_add_more_updates" v-if="show_modal.cant_add_more_updates" @close_modal="closeModal('cant_add_more_updates')" />

				<modal-prize id="modal_congratulations_prize" v-if="show_modal.congratulations_prize" :prize="prize" @close_modal="closeModal('congratulations_prize')"/>

				<modal-report-people id="modal_report_people" v-if="show_modal.report_people" :requiredData="requiredData.report_people" @toggle_modal="closeModal('report_people')"/>

				<modal-reset-password id="modal_reset_password" v-if="show_modal.reset_password" :requiredData="requiredData.reset_password" @close_modal="closeModal('reset_password')"/>
				<modal-webinar-tehnic id="modal_webinar_tbf" v-if="show_modal.webinar_tbf" @close_modal="closeModal('webinar_tbf')"/>
				<modal-contact id="modal_contact" v-if="show_modal.contact" @close_modal="closeModal('contact')"/>
				<modal-translate id="modal_translate" v-if="show_modal.translate" :component="componentTranslation" :translationData="arrayTranslationKeys" @close_modal="closeModal('translate')"/>
				<modal-confirm-archiving id="modal_confirm_archiving" v-if="show_modal.confirm_archiving" :data="requiredData.confirm_archiving" @close_modal="closeModal('confirm_archiving')" />
				<modal-mission-vision id="modal_mission_vision" v-if="show_modal.mission_vision" @close_modal="closeModal('mission_vision')" />
				<modal-settings-mobile id="modal_settings_mobile" v-if="show_modal.settings_mobile" @close_modal="closeModal('settings_mobile')"/>
				<modal-procedure-check id="modal_procedure_check" v-if="show_modal.procedure_check" @close_modal="closeModal('procedure_check')" />

				<modal-calendar-period id="modal_calendar_period" v-if="show_modal.calendar_period" :data="requiredData.calendar_period" @intervalData="getIntervalData" @close_modal="closeModal('calendar_period')"/>
            	<modal-progress-simple id="modal_progress_simple" v-if="show_modal.progress_simple" :data="requiredData.progress_simple" @saveProgressData="getProgressData" @close_modal="closeModal('progress_simple')"/>
			</div>
		</template>
		<div class="page-loader-on-start" v-else>
            <div class="loader-spin"></div>
        </div>
	</div>
</template>

<script>
	import NavbarTbfDigital from "../General/Navbar"
	import SidebarTbfDigital from "../General/Sidebar"
	import ModalKeyResult from "../Modals/KeyResult"
	import ModalKeyResultModals from "../Modals/KeyResultModals"
	import ModalObjective from "../Modals/Objective"
	import ModalMasterGoal from "../Modals/MasterGoal"
	import ModalProfileSettings from "../Modals/Settings"
	import ModalNotitifications from "../Modals/Notifications"
	import ModalUser from "../Modals/User"
	import ModalUpdateKeyResult from "../Modals/UpdateKeyResult"
	import ModalAddPromise from "../Modals/AddPromise"
	import ModalEvaluatePromise from "../Modals/EvaluatePromise"
	import ModalDelete from "../Modals/Delete"
	import ModalCantCreateMore from "../Modals/CantCreateMore"
	import ModalCantCreateMoreObjective from "../Modals/CantCreateMoreObjective"
	import ModalChangeSubscription from "../Modals/ChangeSubscription"
	import ModalPaymentError from "../Modals/PaymentError"
	import ModalTutorialPromise from "../Modals/TutorialPromise"
	import ModalCongratulations from "../Modals/Congratulations"
	import ModalShareMasterGoal from "../Modals/ShareMasterGoal"
	import ModalPrize from "../Modals/Prize"
	import ModalTag from "../Modals/Tag"
	import ModalCantAddMoreUpdates from "../Modals/CantAddMoreUpdates"
	import ModalReportPeople from "../Modals/ReportPeople"
	import ModalRequiredInstance from "../Modals/RequiredInstance"
    import ModalResetPassword from "../Modals/ResetPassword"

	import SidebarTbfDigitalMobile from "../General/SidebarMobile"
	import ModalSettingsMobile from "../General/SidebarSettingsMobile"
	import ModalTranslate from "../General/ModalTranslate"
    import ModalContact from "../Modals/Contact"
    import ModalConfirmArchiving from "../Modals/ConfirmArchiving"
    import ModalWebinarTehnic from "../Modals/WebinarTehnic"
    import ModalMissionVision from "../Modals/MissionVision"
    import ModalProcedureCheck from "../Modals/ProcedureCheck"
    import ModalCalendarPeriod from "../Modals/CalendarPeriodSingle"
    import ModalProgressSimple from "../Modals/ProgressSimple"
	import Notifications from "../General/Notifications"

	export default {
		data() {
			return {
                loadedApplications: false,
				main_path: '',
				show_overflow: false,
				show_overflow_profile: false,
				show_modal: {
					key_result: false,
					key_result_modals: false,
					profile_settings: false,
					notifications: false,
					add_promise: false,
					evaluate_promise: false,
					user: false,
					tag: false,
					objective: false,
					update_key_result: false,
					master_goal: false,
					change_subscription: false,
					payment_error: false,
					tutorial_promise: false,
					congratulations: false,
					congratulations_prize: false,
					cant_add_more_updates: false,
					translate: false,
					report_people: false,
					required_instance: false,
                    reset_password: false,
                    contact: false,
                    confirm_archiving: false,
                    webinar_tbf: false,
                    mission_vision: false,
					settings_mobile: false,
					procedure_check: false,
                    calendar_period: false,
                    progress_simple: false
				},
				modelId:{
					user : '',
					tag : '',
					objective: '',
					master_goal: '',
					key_result: '',
					key_result_modals: '',
					add_promise: ''
				},
				model: {
					delete: {}
				},
				requiredData: {
					key_result: {},
					update_key_result: {},
					master_goal: {},
					objective: {},
					report_people: {},
                    reset_password: {},
                    confirm_archiving: {},
                    calendar_period: {},
                    progress_simple: {}
				},
				typeDelete: false,
				fromDelete: false,
				userData: {
					objective: {},
					key_result: {},
					update_key_result: {}
				},
				active_modal: '',
				active_modal_list: [],
				modalFromPage: '',
				modalKeyTab: '',
				krUuid: '',
				prize: {},
				evaluate_promise: {},
				arrayTranslationKeys: [],
				componentTranslation: ''
			}
		},
		components: {
			NavbarTbfDigital,
			SidebarTbfDigital,
			ModalKeyResult,
			ModalKeyResultModals,
			ModalObjective,
			ModalProfileSettings,
			ModalUser,
			ModalUpdateKeyResult,
			ModalNotitifications,
			ModalMasterGoal,
			ModalAddPromise,
			ModalEvaluatePromise,
			ModalDelete,
			ModalCantCreateMore,
			ModalCantCreateMoreObjective,
			ModalChangeSubscription,
			ModalPaymentError,
			ModalTutorialPromise,
			ModalCongratulations,
			ModalShareMasterGoal,
			SidebarTbfDigitalMobile,
			ModalCantAddMoreUpdates,
			ModalPrize,
			ModalTag,
			ModalReportPeople,
			ModalRequiredInstance,
			ModalSettingsMobile,
			ModalTranslate,
			ModalResetPassword,
			ModalContact,
			ModalConfirmArchiving,
			ModalWebinarTehnic,
			ModalMissionVision,
			ModalProcedureCheck,
            ModalCalendarPeriod,
            ModalProgressSimple,
			Notifications
		},
		watch:{
			$route (to, from){
				this.closeModal()
				this.closeModalV2()
			}
		},
		mounted(){
			if(this.$store.state.applications.applications.length){
                this.initItercom();
                this.loadedApplications = true;
            } else {
                var refreshIntervalApps = setInterval(() => {
                    if(this.$auth.check()){
                        if(this.$store.state.applications.applications.length){
                            clearInterval(refreshIntervalApps);
                            this.initItercom();
                            this.loadedApplications = true;
                        }
                    }
                }, 50);
            }

			this.$root.$on('open_modal_translation', (component, arrayData) => {
				this.show_modal.translate = true
				this.arrayTranslationKeys = arrayData
				this.componentTranslation = component
				setTimeout(() => {
					$("#modal_translate").addClass("active");
				}, 0);
			})

			this.$root.$on("open_modal", (type, model = false, requiredData = false, userData = false, fromPage = false) => {
				this.active_modal = type
				this.active_modal_list.push(type);
				this.modalFromPage = fromPage
				this.modelId[type] = model ? model : ''
				this.requiredData[type] = requiredData ? requiredData : {}
				this.userData[type] = userData ? userData : {}
                setTimeout(() => {
                    this.show_overflow = true
					this.show_modal[type] = true
                    setTimeout(() => {
                    	$('.overflow-modal').addClass('show');
            			$("#modal_" + type).addClass("active");
						if((fromPage == 'objective' || fromPage == 'keyresult') && type == 'user'){
            				$("#modal_" + type).addClass("smaller");
			            }
			            if(fromPage == 'objective' && type == 'master_goal'){
            				$("#modal_" + type).addClass("smaller");
			            }
                    }, 0);
                }, 0);
            });

			this.$root.$on("evaluate_promise_modal", (promise = false, fromPage = false) => {
				this.active_modal = 'evaluate_promise';
				this.active_modal_list.push('evaluate_promise');
				this.modalFromPage = fromPage
				this.evaluate_promise = promise ? promise : null
                setTimeout(() => {
                    this.show_overflow = true
					this.show_modal['evaluate_promise'] = true
                    setTimeout(() => {
                    	$('.overflow-modal').addClass('show');
            			$("#modal_evaluate_promise").addClass("active");
                    }, 0);
                }, 0);
            })

            this.$root.$on('open_key_result_modals', (model = false, uuid = false, activeTab, fromPage = false) => {
				this.active_modal = 'key_result_modals'
				this.active_modal_list.push('key_result_modals');
				this.modalFromPage = fromPage
				this.modelId['key_result_modals'] = model ? model : ''
				this.krUuid = uuid ? uuid : ''
				this.modalKeyTab = activeTab

				setTimeout(() => {
                    this.show_overflow = true;
					this.show_modal['key_result_modals'] = true
                    setTimeout(() => {
                    	$('.overflow-modal').addClass('show');
            			$("#modal_key_result_modals").addClass("active");
                    }, 0);
                }, 0);
            })

            this.$root.$on('open_modal_v2', (type, model = false, typeDelete = false, fromDelete = false) => {
				this.model[type] = model ? model : {}
				this.typeDelete = typeDelete ? typeDelete : false
				this.fromDelete = fromDelete ? fromDelete : false
				this.active_modal = type
            	setTimeout(() => {
            		this.show_overflow = true;
            		this.show_modal[type] = true
            		setTimeout(() => {
            			$('.overflow-modal').addClass('show');
	                   	$("#modal_" + type).addClass("active");
            		}, 0);
            	}, 0);
            })

            this.$root.$on('change_modal', (type) => {
				$("#modal_" + this.active_modal).removeClass("active")
				setTimeout(() => {
					this.show_modal[this.active_modal] = false
					setTimeout(() => {
						this.show_modal[type] = true
						this.active_modal = type
						setTimeout(() => {
							$("#modal_" + type).addClass("active");
						}, 0);
					}, 0);
				}, 200);
            })

            this.$root.$on('won_prize', (prize) => {
            	$("#modal_" + this.active_modal).removeClass("active")
				setTimeout(() => {
					this.show_modal[this.active_modal] = false
					setTimeout(() => {
						this.show_modal['congratulations_prize'] = true
						this.active_modal = 'congratulations_prize'
						this.prize = prize
						setTimeout(() => {
							$("#modal_congratulations_prize").addClass("active");
						}, 0);
					}, 0);
				}, 200);
            })

            if(this.$auth.check() && this.$auth.user().is_entrepreneur && this.$auth.user().status == 'grace_period' && !this.$cookies.get('grace_period_modal')){
                this.$cookies.set('grace_period_modal', true, '1d')
            	this.$root.$emit('open_modal', 'payment_error')
            }
			
			this.checkLanguage();

		    document.addEventListener("keydown", (e) => {
		        if (e.keyCode == 27) {
		            this.closeModals();
		        }
		    });
		},
		methods:{
			closeModals(){
				var arrayModalsNeedVerification = ['key_result', 'objective', 'master_goal', 'user', 'add_promise', 'evaluate_promise']
				if(arrayModalsNeedVerification.includes(this.active_modal)){
					this.$root.$emit('confirm_close')
				}else{
					this.closeModal()
				}
			},
			closeModal(type = false){
				var target_modal = type ? type : this.active_modal
				$("#modal_" + target_modal).removeClass("active")
				this.active_modal_list = this.active_modal_list.filter(item => item !== target_modal)

				if(this.active_modal_list.length != 0) {
					this.active_modal = this.active_modal_list[this.active_modal_list.length - 1]
				}
				if(this.active_modal == 'user'){
					this.$root.$emit('visibilityHiddenUser', false);
				}
				if(this.active_modal == 'objective'){
					this.$root.$emit('visibilityHiddenObjective', false);
				}
				if(this.active_modal == 'key_result_modals'){
					this.$root.$emit('visibilityHiddenKeyResultPromises', false);
				}

				setTimeout(() => {
					this.show_modal[target_modal] = false
					if(this.active_modal_list.length == 0 || this.active_modal_list[0] == 'payment_error') {
						$('.overflow-modal').removeClass('show')
						setTimeout(() => {
							this.show_overflow = false;
						}, 200);
					}
				}, 200);
			},
			closeModalV2(type = false){
				var target_modal = type ? type : this.active_modal
				$("#modal_" + target_modal).removeClass("active")
				setTimeout(() => {
					this.show_modal[target_modal] = false
					$('.overflow-modal-profile').removeClass('show')
					setTimeout(() => {
						this.show_overflow_profile = false;
					}, 200);
				}, 200);
			},
            initItercom(){
            	var apps = {
            		'Obiective': false,
            		'Proceduri': false,
            		'Cursuri': this.$auth.user().has_acces_to_courses,
            		'Organigrama': false	
            	};

            	this.$store.state.applications.applications.map((el) => {
                    if(el.application){
                        apps[el.application.name] = el.is_active;
                    }
                });

            	Intercom('boot', {  
            		app_id: "tk8wk42t",
            		name: this.$auth.user().first_name + ' ' + this.$auth.user().last_name,
            		email: this.$auth.user().email,
            		user_hash: this.$auth.user().intercom_token,
            		package_id: this.$auth.user().instance ? this.$auth.user().instance.package_id : '',
            		created_at: null,
            		hide_default_launcher: this.$resize && this.$mq.above(780) ? false : true,
            		'Obiective': apps['Obiective'],
            		'Proceduri': apps['Proceduri'],
            		'Cursuri': apps['Cursuri'],
            		'Organigrama': apps['Organigrama'] 
            	});
            },
			checkLanguage(){
				if(this.$auth.check()){
					this.$i18n.locale = this.$auth.user().language
					this.$cookies.set('language', this.$auth.user().language, '1m')
				}else if(this.$cookies.get('language')){
					this.$i18n.locale = this.$cookies.get('language')
				}
			},
            getIntervalData(value){
                this.$root.$emit('getIntervalDataTemplate', value)
            },
            getProgressData(value){
                this.$root.$emit('getProgressDataTemplate', value)
            }
		}
	}
</script>
