<template>
	<div class="opacity-page" v-if="loaded">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Show Objective', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>
		<div class="item-page" v-if="notAllowed">
			<not-allowed></not-allowed>
		</div>
		<div class="item-page" v-else-if="notFound">
			<not-found></not-found>
		</div>
		<div class="item-page" v-else>
			<section class="mobile-title" v-if="$resize && $mq.below(600)">
				<div class="space-left"></div>
				<div class="content-section">
					<div class="title">{{ objective.name }}
					<v-popover offset="10" trigger="hover click" placement="bottom" popoverBaseClass="popover-tbf" popoverClass="description-popover" :delay="{show: 100, hide: 0}" class="margin-left-desc">
						<icon-info class="icon-info"/>

						<template slot="popover">
							<div class="description">{{ objective.description }}</div>
						</template>
					</v-popover>
				</div>
					
				</div>
				<div class="space-right"></div>
			</section>

			<section class="principal-info-section">
				<div class="space-left">
				</div>
				<div class="content-section">
					<div class="cards-info carousel" v-if="$resize && $mq.below(1030)">
						<carousel-3d :autoplay="false" :loop="true" :display="3" :perspective="0" :border="0" :height="130" :space="$mq.below(780) ? 'auto' : 380">
							<slide :index="0">
								<div class="info-card responsabled-card">
									<div class="data-image">
										<img :src="objective.user.avatar" v-if="objective.user.avatar">
										<div class="user-circle" v-else>
											<icon-user-settings/>
										</div>
									</div>
									<div class="data-card">
										<div class="value">{{ objective.user.first_name + ' ' + objective.user.last_name }}</div>
										<div class="description">
											<span class="label">{{$t('objectives.responsible')}}</span>
										</div>
									</div>
									<div class="reward">
										<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf reward-popover small" :delay="{show: 200, hide: 0}">										
											<div class="icon" v-if="objective.reward.filename || objective.reward.name ">
												<icon-reward />
											</div>
			
											<template slot="popover">
												<img :src="objective.reward.file_path" v-bind:class="{mrg_bot: objective.reward.name != ''}" v-if="objective.reward.file_path">
												<div class="simple-text" v-if="objective.reward.name">{{ objective.reward.name }}</div>
											</template>
										</v-popover>
									</div>
								</div>
							</slide>
							<slide :index="1">
								<div class="info-card progress-card">
									<div class="data-icon">
										<img src="/build/icons/progress.svg">
									</div>
									<div class="data-card">
										<div class="value">{{ parseInt(objective.percent).toString() }} %</div>
										<div class="description">
											<span class="label">{{$t('objectives.total-progress')}}</span>
											<span class="count-data">{{ objective.key_results_finished }} {{$t('objectives.completed')}}</span>
										</div>
										<div class="progress-bar-tbf">
											<div class="complete-progress-tbf blue" :style="'width: calc(2px + ' + (objective.percent < 1 ? '2px' : objective.percent + '%') + ');'"></div>
										</div>
									</div>
								</div>
							</slide>
							<slide :index="2">
								<div class="info-card deadline-card">
									<div class="data-icon">
										<img src="/build/icons/end-date.svg">
									</div>
									<div class="data-card">
										<div class="value">{{ objective.end_date | moment('DD MMMM')}}</div>
										<div class="description">
											<span class="label">{{$t('objectives.deadline')}}</span>
											<span class="count-data">{{ diffDays(objective.end_date) }} {{ $t('calendar.days_left') }}</span>
										</div>
										<div class="progress-bar-tbf">
											<div class="complete-progress-tbf blue" :style="'width: calc(2px + ' + (percentIntervalDays() < 1 ? '2px' : percentIntervalDays() + '%') + ');'"></div>
										</div>
									</div>
								</div>
							</slide>
						</carousel-3d>
					</div>
					<div class="cards-info" v-else>
						<div class="info-card responsabled-card">
							<div class="data-image">
								<img :src="objective.user.avatar" v-if="objective.user.avatar">
								<div class="user-circle" v-else>
									<icon-user-settings/>
								</div>
							</div>
							<div class="data-card">
								<div class="value">{{ objective.user.first_name + ' ' + objective.user.last_name }}</div>
								<div class="description">
									<span class="label">{{$t('objectives.responsible')}}</span>
								</div>
							</div>
							<div class="reward">
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf reward-popover" :delay="{show: 200, hide: 0}">										
									<div class="icon" v-if="objective.reward.filename || objective.reward.name ">
										<icon-reward />
									</div>
	
									<template slot="popover">
										<img :src="objective.reward.file_path" v-bind:class="{mrg_bot: objective.reward.name != ''}" v-if="objective.reward.file_path">
										<div class="simple-text" v-if="objective.reward.name">{{ objective.reward.name }}</div>
									</template>
								</v-popover>
							</div>
						</div>
						<div class="info-card progress-card">
							<div class="data-icon">
								<img src="/build/icons/progress.svg">
							</div>
							<div class="data-card">
								<div class="value">{{ parseInt(objective.percent).toString() }} %</div>
								<div class="description">
									<span class="label">{{$t('objectives.total-progress')}}</span>
									<span class="count-data">{{ objective.key_results_finished }} {{$t('objectives.completed')}}</span>
								</div>
								<div class="progress-bar-tbf">
									<div class="complete-progress-tbf blue" :style="'width: calc(2px + ' + (objective.percent < 1 ? '2px' : objective.percent + '%') + ');'"></div>
								</div>
							</div>
						</div>
						<div class="info-card deadline-card">
							<div class="data-icon">
								<img src="/build/icons/end-date.svg">
							</div>
							<div class="data-card">
								<div class="value">{{ objective.end_date | moment('DD MMMM')}}</div>
								<div class="description">
									<span class="label">{{$t('objectives.deadline')}}</span>
									<span class="count-data">
										<div v-if="diffDays(this.objective.end_date) < 7">
											
										{{ diffDays(this.objective.end_date) == 0 ? '' : diffDays(this.objective.end_date) }}
										{{ ((diffDays(this.objective.end_date)) % 7 ) != 0 ? ((diffDays(this.objective.end_date)) % 7 ) == 1 ? $t('calendar.one_day_left') : $t('calendar.days_left') : $t('calendar.today')}}
										</div>
										<div v-else>
											{{ Math.trunc((diffDays(objective.end_date)) / 7) }} 
											{{ $t('calendar.weeks_left') }} 
											{{ ((diffDays(objective.end_date)) % 7 ) != 0 ? ((diffDays(objective.end_date)) % 7 ) : '' }} 
											{{ ((diffDays(objective.end_date)) % 7 ) != 0 ? ((diffDays(objective.end_date)) % 7 ) == 1 ? $t('calendar.day_left') : $t('calendar.days_left') : "" }}
										</div>
									</span>
								</div>
								<div class="progress-bar-tbf">
									<div class="complete-progress-tbf blue" :style="'width: calc(2px + ' + (percentIntervalDays() < 1 ? '2px' : percentIntervalDays() + '%') + ');'"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</section>

			<section class="header-list-section">
				<div class="space-left"><icon-list class="list-icon" /></div>
				<div class="content-section">
					<div class="header-table">
						<div class="title">{{$t('objectives.key-results')}} ({{objective.key_results_count}})</div>

						<div class="action-header filter-date dropdown-tbf">
							<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="$resize && $mq.above(600)">
								{{ $t('filter_objectives_date.' + activeDate) }}<icon-arrow class="icon-arrow"/>
							</button>

							<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else><icon-filter /></button>

							<div class="dropdown-menu" aria-labelledby="dropdownFilter">
								<div class="dropdown-item" @click="activeDate = 'asc'" v-bind:class="{active: activeDate == 'asc'}">
									{{ $t('filter_objectives_date.asc')}}
								</div>
								<div class="dropdown-item" @click="activeDate = 'desc'" v-bind:class="{active: activeDate == 'desc'}">
									{{ $t('filter_objectives_date.desc')}}
								</div>
							</div>
						</div>

						<div class="action-header filter-date dropdown-tbf">
							<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="$resize && $mq.above(600)">
								{{ $t('filter_objectives.' + activeStatus) }} <icon-arrow class="icon-arrow"/>
							</button>

							<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else><icon-filter /></button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilter">
								<div v-for="objStatus in objStatusList" 
								class="dropdown-item" 
								@click="changeActiveStatus(objStatus)" 
								v-bind:class="{active: activeStatus == objStatus}">
								{{ $t('filter_objectives.'+objStatus) }}
								</div>
							</div>
						</div>
								
						<div class="add-button" v-if="$auth.user().rights.can_create">
							<button class="button-tbf-blue" @click="showModal('key_result', '', objective)"><icon-plus class="white" />{{ $resize && $mq.above(600) ? $t('objectives.add-btn') : ''}}</button>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</section>
			<!-- list key results -->
			<section class="list-items-section" v-if="filteredKrs.length">
				<div class="group_items" v-for="key_result in filteredKrs">
					<div class="space-left">
						<icon-arrow :id="'arrow-key-result-' + key_result.id" class="right"/>
					</div>
					<div class="content-row">
						<template v-if="$resize && $mq.above(600)">
							<div class="row-tbf action-row" v-bind:class="{completed: key_result.status == 'finished', upcoming: key_result.status == 'upcoming'}" @click="showModalKR(parseInt(key_result.id).toString(), key_result.uuid, 'updates')">
								<!-- asigned user -->
								<div class="column-tbf user-img kr-user-width">
									<v-popover offset="10" trigger="hover click" placement="bottom" popoverBaseClass="popover-tbf" popoverClass="description-popover" :delay="{show: 200, hide: 0}">
										<img :src="key_result.user.avatar" v-if="key_result.user.avatar">
										<div class="user-circle" v-else>
											<icon-user-settings/>
										</div>
										<template slot="popover">
											<div class="description">{{ key_result.user.first_name+" "+key_result.user.last_name }}</div>
										</template>
									</v-popover>
								</div>
								<!-- end assigned user -->
								<!-- RC name box -->
								<div class="column-tbf row-title with-icon kr-name-width">
									<v-popover offset="10" trigger="hover click" placement="bottom" popoverBaseClass="popover-tbf" popoverClass="description-popover" :delay="{show: 200, hide: 0}">
										<div class="text text-on-two-rows">{{ key_result.name.charAt(0).toUpperCase() + key_result.name.slice(1) }}</div>
	
										<template slot="popover">
											<div class="description">{{ key_result.description }}</div>
										</template>
									</v-popover>
								</div>
								<!-- end rc name box -->
	
								<!-- promises box -->
								<div class="column-tbf column-value kr-promises-width" v-if="$resize && $mq.above(1030)" @click.stop="showModalKR(parseInt(key_result.id).toString(), key_result.uuid, 'promises')">
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
										<div class="label">{{$t('show-user.promises')}}</div>
										<div class="value" v-if="key_result.need_evaluate_promise"><img class="circle-info" src="/build/icons/circle-info-orange.svg"> {{ $t('show-user.status-pending') }}</div>
										<div class="value" v-else>{{ key_result.promises_completed }}/{{ key_result.promises_count }} {{ $t('key-result-promises.completed')}}</div>
	
										<template slot="popover">
											<div class="simple-text" v-if="key_result.status == 'upcoming'">{{ $tc('key-result.tooltip-promises-cant') }}</div>
											<div class="simple-text" v-else>{{ key_result.need_evaluate_promise ? $t('key-result.tooltip-promises-evaluate') : $t('key-result.tooltip-promises') }}</div>
										</template>
									</v-popover>
								</div>
								<!-- end promises box -->
	
								<!-- updates box -->
								<div class="column-tbf column-value kr-updates-width" v-if="$resize && $mq.above(1030)" @click.stop="showModalKR(parseInt(key_result.id).toString(), key_result.uuid, 'updates')">
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
										<div class="label">{{$t('show-user.updates')}}</div>
	
										<div class="value" v-if="key_result.needs_an_update == 'today' || key_result.needs_an_update == 'past'"><img class="circle-info" src="/build/icons/circle-info-orange.svg"> {{ $t('show-user.status-pending') }}</div>
										<div class="value" v-else-if="key_result.last_key_result_log"><img class="circle-info" src="/build/icons/circle-check-green.svg"> {{ $tc('show-user.days-ago', diffDaysFrom(key_result.last_key_result_log.created_at)) }}</div>
										<div class="value" v-else>{{ $t('show-user.no-updates') }}</div>
	
										<template slot="popover">
											<div class="simple-text" v-if="key_result.needs_an_update == 'today'">{{ $t('key-result.tooltip-update-today') }}</div>
											<div class="simple-text" v-else-if="key_result.needs_an_update == 'past'">{{ $t('key-result.tooltip-update-past') }}</div>
											<div class="simple-text" v-else-if="key_result.needs_an_update">{{ $tc('key-result.tooltip-update-future', key_result.needs_an_update) }}</div>
											<div class="simple-text" v-else-if="key_result.status == 'upcoming'">{{ $t('key-result.tooltip-update-cant') }}</div>
											<div class="simple-text" v-else>{{ $tc('key-result.tooltip-updates-view') }}</div>
										</template>
									</v-popover>
								</div>
								<!-- end updates box -->
	
								<div class="column-tbf column-value kr-deadline-width" v-if="$resize && $mq.above(1030)">
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
										<div class="label">{{ $t('show-user.deadline') }}</div>
	
										<div class="value" v-if="key_result.percent >= 100"><img class="circle-info" src="/build/icons/circle-check-green.svg"> {{ $t('status.completed') }}</div>
										<div class="value" v-else-if="diffDays(key_result.end_date) < 0"><img class="circle-info" src="/build/icons/circle-info-red.svg"> {{ $t('show-user.outdated') }}</div>
										<div class="value" v-else>
											{{ diffWeeksAndDays(key_result.end_date) }}
										</div>
	
										<template slot="popover">
											<div class="simple-text">{{ key_result.end_date | moment('D MMMM YYYY') }}</div>
										</template>
									</v-popover>
								</div>
	
	
								<!-- last values box -->
								<div class="column-tbf column-value kr-last-value-width" v-if="$resize && $mq.above(780)">
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
										<div class="label">{{ $t('show-user.last-value') }}</div>
										<div class="value" v-if="displayKeyResultCurrentValue(key_result) % 1 != 0">{{ displayKeyResultCurrentValue(key_result) | numeral('0.00a') }}{{ optionsUnits[key_result.unity] ? key_result.unity : ''}} {{ !optionsUnits[key_result.unity] ? key_result.unity : optionsUnits[key_result.unity]}}</div>
										<div class="value" v-else>{{ displayKeyResultCurrentValue(key_result) | numeral('0a') }}{{ optionsUnits[key_result.unity] ? key_result.unity : ''}} {{ !optionsUnits[key_result.unity] ? key_result.unity : "("+optionsUnits[key_result.unity]+")"}}</div>
										
										<template slot="popover">
											<div class="simple-text">{{ $t('progress_type_text.' + key_result.progress_type, { to: key_result.target % 1 != 0 ? $options.filters.numeral(key_result.target ,  '0,0.00') : $options.filters.numeral(key_result.target ,  '0,0'), unit: key_result.unity, from: key_result.start_value % 1 != 0 ? $options.filters.numeral(key_result.start_value ,  '0,0.00') : $options.filters.numeral(key_result.start_value ,  '0,0')}) }}</div>
										</template>
									</v-popover>
								</div>
								<!-- end last values box -->
								
								<!-- Percentage box -->
								<div class="column-tbf item-value kr-value-width" v-if="$resize && $mq.above(600)">
									<v-popover offset="10" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
										<div class="top-data">
											<div class="current-value" v-if="moment(key_result.start_date) >= moment()">
												<div class="value">0</div>
											</div>
											<div class="current-value" v-else>
												<div class="value">{{ parseInt(key_result.percent).toString()+'%' }}</div>
											</div>
											<div class="info"><icon-info class="icon-info"/></div>
										</div>
										<div class="progress-bar-tbf" v-bind:class="{disabled: moment(key_result.start_date) > moment()}">
											<div class="complete-progress-tbf" v-bind:class="{pink: diffDays(key_result.end_date) < 1 && key_result.percent != 100, green: key_result.percent >= 100}" :style="'width: calc(2px + ' + (parseFloat(key_result.percent) < 1 ? '2px' : (key_result.percent < 100 ? key_result.percent + '%' : '100%')) + ');'"></div>
										</div>
	
										<template slot="popover">
											<div class="simple-text">
												<span v-if="key_result.target % 1 != 0 || key_result.start_value % 1 != 0">{{ $t('progress_type_text.' + key_result.progress_type, { to: $options.filters.numeral(key_result.target ,  '0,0.00'), unit: key_result.unity, from: $options.filters.numeral(key_result.start_value, '0,0.00')}) }}. </span>
												<span v-else>{{ $t('progress_type_text.' + key_result.progress_type, { to: $options.filters.numeral(key_result.target ,  '0,0'), unit: key_result.unity, from: $options.filters.numeral(key_result.start_value, '0,0')}) }}. </span>
	
												<span v-if="displayKeyResultCurrentValue(key_result) % 1 != 0">{{ $t('progress_type_text.current_value') }}: {{displayKeyResultCurrentValue(key_result) | numeral('0,0.00')}} {{ key_result.unity }}</span>
												<span v-else>{{ $t('progress_type_text.current_value') }}: {{displayKeyResultCurrentValue(key_result) | numeral('0,0')}} {{ key_result.unity }}</span>
											</div>
										</template>
									</v-popover>
								</div>
								<!-- End percentage box -->
							</div>
						</template>
						<template v-else>

							<div class="row-tbf action-row" v-bind:class="{completed: key_result.status == 'finished', upcoming: key_result.status == 'upcoming'}" @click="showModalKR(parseInt(key_result.id).toString(), key_result.uuid, 'updates')">
								<!-- asigned user -->
								<div class="column-tbf user-img kr-user-width">
									<v-popover offset="10" trigger="hover click" placement="bottom" popoverBaseClass="popover-tbf" popoverClass="description-popover" :delay="{show: 200, hide: 0}">
										<img :src="key_result.user.avatar" v-if="key_result.user.avatar">
										<div class="user-circle" v-else>
											<icon-user-settings/>
										</div>
										<template slot="popover">
											<div class="description">{{ key_result.user.first_name+" "+key_result.user.last_name }}</div>
										</template>
									</v-popover>
								</div>
								<!-- end assigned user -->
								<!-- RC name box -->
								<div class="column-tbf row-title with-icon kr-name-width">
									<v-popover offset="10" trigger="hover click" placement="bottom" popoverBaseClass="popover-tbf" popoverClass="description-popover" :delay="{show: 200, hide: 0}">
										<div class="text text-on-two-rows">{{ key_result.name.charAt(0).toUpperCase() + key_result.name.slice(1) }}</div>
	
										<template slot="popover">
											<div class="description">{{ key_result.description }}</div>
										</template>
									</v-popover>
								</div>
								<!-- end rc name box -->
								<!-- Percentage box -->
								<vc-donut background="#FFF" foreground="#f0f0f0"
								:size="50" :thickness="25" :text="parseInt(key_result.percent).toString()+'%'"
								:sections="[{value: parseInt(key_result.percent) > 100 ? 100 : parseInt(key_result.percent), color: '#03ae58'}]"/>
								<!-- End percentage box -->
							</div>
						</template>
					</div>
					<div class="space-right">
						<div class="dots-edit" :id="'edit-key-result-' + key_result.id" v-if="key_result.rights.edit || key_result.rights.delete">
							<div class="dropdown edit-item-dropdown">
								<div class="overlay-button" @click.stop="showDropdown(key_result.id)"></div>
								<div class="edit-item-button" :id="'dropdownEdit'+key_result.id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<icon-edit-dots />
								</div>
								<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ key_result.id " :aria-labelledby="'dropdownEdit'+key_result.id">
									<div class="dropdown-item" @click.stop="showModal('key_result', parseInt(key_result.id).toString(), key_result.objective)" v-if="key_result.rights.edit">{{$t('objectives.edit')}}</div>
									<div class="dropdown-item" @click.stop="openModalV2('delete', key_result, 'kr', 'show_objective')" v-if="key_result.rights.delete">{{$t('objectives.delete')}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- end list key results -->
			<!-- no key results message -->
			<section class="list-items-section" v-else>
				<div class="group_items">
					<div class="space-left"></div>
					<div class="empty-row mrgn">
						<div class="title single">
							{{$t('objectives.no-key.title')}}
						</div>
						<div class="description wide" v-html="$t('objectives.no-key.desc')"></div>
						<div class="add-button" v-if="$auth.user().rights.can_create">
							<button class="button-tbf-blue" @click="showModal('key_result', '', objective)"><icon-plus class="white" />{{$t('objectives.add-btn')}}</button>
						</div>
					</div>
					<div class="space-right"></div>
				</div>
			</section>
			<!-- end no key results message -->
		</div>
	</div>
	<!-- placeholder list -->
	<div v-else>
		<div class="item-page">
			<section class="principal-info-section">
				<div class="space-left">
				</div>
				<div class="content-section">
					<div class="cards-info">
						<div class="info-card responsabled-card">
							<div class="data-image">
								<div class="placeholder-loader user" style="height: 46px; width: 46px;"></div>
							</div>
							<div class="data-card">
								<div class="value placeholder-loader" style="height: 21px; width: 146px;"></div>
								<div class="description">
									<span class="label placeholder-loader" style="height: 14px; width: 126px; display: inline-block;"></span>
								</div>
							</div>
						</div>
						<div class="info-card progress-card">
							<div class="data-icon">
								<div class="placeholder-loader" style="height: 48px; width: 48px;"></div>
							</div>
							<div class="data-card">
								<div class="value placeholder-loader" style="height: 21px; width: 56px;"></div>
								<div class="description">
									<span class="label placeholder-loader" style="height: 14px; width: 30px; display: inline-block;"></span>
									<span class="count-data placeholder-loader" style="height: 14px; width: 100px; display: inline-block;"></span>
								</div>
								<div class="progress-bar-tbf placeholder-loader"></div>
							</div>
						</div>
						<div class="info-card deadline-card">
							<div class="data-icon">
								<div class="placeholder-loader" style="height: 48px; width: 48px;"></div>
							</div>
							<div class="data-card">
								<div class="value placeholder-loader" style="height: 21px; width: 56px;"></div>
								<div class="description">
									<span class="label placeholder-loader" style="height: 14px; width: 30px; display: inline-block;"></span>
									<span class="count-data placeholder-loader" style="height: 14px; width: 100px; display: inline-block;"></span>
								</div>
								<div class="progress-bar-tbf placeholder-loader"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</section>

			<section class="header-list-section">
				<div class="space-left"><icon-list class="list-icon" /></div>
				<div class="content-section">
					<div class="header-table">
						<div class="title">
							<div class="placeholder-loader" style="height: 19px; width: 146px;"></div>
						</div>
						<div class="add-button">
							<div class="placeholder-loader" style="height: 37px; width: 90px;"></div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</section>

			<section class="list-items-section">
				<div class="group_items" v-for="n in 5">
					<div class="space-left">
						<icon-arrow class="right"/>
					</div>
					<div class="content-row">
						<div class="row-tbf">
							<div class="column-tbf user-img kr-user-width">
								<div class="placeholder-loader user" style="height: 38px; width: 38px;"></div>
							</div>
							<div class="column-tbf row-title with-icon kr-name-width">
								<div class="text placeholder-loader" style="height: 21px; width: 170px;"></div>
							</div>

							<div class="column-tbf status kr-updates-width" v-if="$resize && $mq.above(1030)">
								<div class="link-modal disabled">
									<div class="box-icon">
									</div>
									<span><div class="placeholder-loader" style="height: 16px; width: 63px;"></div></span>
								</div>
							</div>

							<div class="column-tbf row-details kr-promises-width" v-if="$resize && $mq.above(1030)">
								<div class="link-modal">
									<div class="label placeholder-loader" style="height: 16px; width: 95px;"></div>
								</div>
							</div>

							<div class="column-tbf row-details kr-tasks-width" v-if="$resize && $mq.above(1030)">
								<div class="link-modal">
									<div class="label placeholder-loader" style="height: 16px; width: 95px;"></div>
								</div>
							</div>

							<div class="column-tbf item-value kr-value-width" v-if="$resize && $mq.above(600)">
								<div class="top-data">
									<div class="current-value">
										<div class="value placeholder-loader" style="height: 16px; width: 15px;"></div>
									</div>
									<div class="deadline placeholder-loader" style="height: 14px; width: 30px;"></div>
								</div>
								<div class="progress-bar-tbf placeholder-loader"></div>
							</div>

						</div>
					</div>
					<div class="space-right">
						<div class="dots-edit">
							<div class="dropdown edit-item-dropdown">
								<div class="edit-item-button">
									<icon-edit-dots />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
	<!-- END placeholder list -->
</template>


<script type="text/javascript">
	import NotAllowed from '../General/NotAllowed'
	import NotFound from '../Pages/NotFound'
	import IconArrow from '../Icons/Arrow'
	import IconPlus from '../Icons/Plus'
	import IconInfo from '../Icons/Info'
	import IconEditDots from '../Icons/EditDots'
	import IconList from '../Icons/List'
	import IconCheckPrimary from "../Icons/CheckPrimary"
	import IconUserSettings from '../Icons/UserSettings'
	import IconFilter from '../Icons/Filter'
	import IconReward from '../Icons/Award'

	import { Carousel3d, Slide } from 'vue-carousel-3d';

	export default {
		data() {
			return {
				loaded: false,
				activetab: 'keyresults',
				activeTooltip: '',
				limitedDescription: true,
				objective: {},
				notAllowed: false,
				notFound: false,
				hoverkeyResult: '',
				activeStatus: 'all',
				activeDate: 'asc',
				objStatusList: [],
				optionsUnits: {
					"€": 'Euro',
					"$": 'Dolar',
					"%": 'Procent',
				},
				arrayTranslations: [
					'objectives.responsible',
					'objectives.total-progress',
					'objectives.completed',
					'objectives.deadline',
					'objectives.key-results',
					'filter_objectives.active',
					'filter_objectives.all',
					'filter_objectives.finished',
					'filter_objectives.overdue',
					'filter_objectives.upcoming',
					'objectives.add-btn',
					'show-user.promises',
					'show-user.status-pending',
					'key-result-promises.completed',
					'key-result.tooltip-promises-cant',
					'key-result.tooltip-promises-evaluate',
					'key-result.tooltip-promises',
					'show-user.updates',
					'show-user.status-pending',
					'show-user.days-ago',
					'show-user.no-updates',
					'key-result.tooltip-update-today',
					'key-result.tooltip-update-past',
					'key-result.tooltip-update-future',
					'key-result.tooltip-update-cant',
					'key-result.tooltip-updates-view',
					'show-user.deadline',
					'status.completed',
					'show-user.outdated',
					'show-user.days',
					'show-user.last-value',
					'progress_type_text.above',
					'progress_type_text.current_value',
					'progress_type_text.decrease',
					'progress_type_text.grow',
					'progress_type_text.under',
					'progress_type_text.value',
					'objectives.edit',
					'objectives.delete',
					'objectives.no-key.title',
					'objectives.no-key.desc',
					'objectives.add-btn',
					'general.objective'
				],
				sections: [], 
				totalSections: 0,
			};
		},
		components: {
			Carousel3d,
			Slide,
			IconUserSettings,
			NotAllowed,
			NotFound,
			IconArrow,
			IconPlus,
			IconInfo,
			IconEditDots,
			IconList,
			IconCheckPrimary,
			IconFilter,
			IconReward
		},
		async mounted() {
			if(this.$route.params.slug){
				await this.getObjStatusList()
				await this.getObjective()
			}else{
				this.$router.push({ name: 'page-404'})
			}

			this.$root.$on("refreshObjectivePage", () => {
				this.getObjective()
            });
		},
		beforeDestroy () {
			this.$root.$off('refreshObjectivePage')
		},
		watch: {
	        $route (to, from){
				if(this.$route.params.slug){
					this.getObjective()
				}else{
					this.$router.push({ name: 'page-404'})
				}
	        }
	    },
	    computed: {
			filteredKrs(){
				return SortByDeadline(getByStatus(this.objective.key_results, this.activeStatus), this.activeDate)
			}
		},
		methods: {
			async getObjective(){
				await axios.get('/objectives/'+this.$route.params.slug)
				.then(({data}) => {
					this.objective = data.data
					this.sections = [
						{
							label: 'Completat',
							value: this.objective,
						}
					]
				}).catch(error => {
					var status = error.response.data.status
					this.notAllowed = status == 'not allowed'
					this.notFound = status == 'not found'

					if(status == 'error' && error.response.data.message == 'Unauthorized'){
						this.$auth.refresh()

						setTimeout(() => {
							if(this.$auth.check()){
								location.reload()
							}
						}, 300)
					}
				}).then(() => {
					setTimeout(()=>{
						this.loaded = true
						var title = this.$resize && this.$mq.above(600) ? this.objective.name : this.$t('general.objective');
						
						if(this.$route.query.form_user) {
							var backRoute = { route: 'show-user', slug: this.$route.query.form_user}
						} else {
							var backRoute = { route: 'objectives'} 
						}

						this.$root.$emit("navbar_title", title, backRoute, this.$resize && this.$mq.above(600) ? this.objective.description : false)
						setTimeout(() => {
							$('.opacity-page').addClass('show')

							this.checkUrlModal()
						}, 0)
					}, 300)
				})
			},
			async getObjStatusList(){
				await axios.get('/objectives/status/list').then(({data}) => {
					this.objStatusList = data.data
					this.objStatusList.unshift("all");
				})
			},
			showModal(type, model = false, requiredData = false, userData = false){
				this.$root.$emit('open_modal', type, model, requiredData, userData, 'show_objective');
				this.hideDropdown()
			},
			openModalV2(type, model = false, typeDelete = false, fromDelete = false){
				this.hideDropdown()
				this.$root.$emit('open_modal_v2', type, model, typeDelete, fromDelete);
			},
			showModalKR(model, uuid, type){
				this.$root.$emit('open_key_result_modals', model, uuid, type, 'show_objective');
				this.hideDropdown()
			},
			percentIntervalDays(){
				var a1 = moment(this.objective.start_date);
				var b1 = moment(this.objective.end_date);
				var intervalDays = b1.diff(a1, 'days')

				var a2 = moment(this.objective.start_date);
				var b2 = moment();
				var daysCompleted = b2.diff(a2, 'days')

				var percent = parseInt((daysCompleted * 100)/intervalDays)
				return percent > 100 ? 100 : percent;
			},
			diffDays(end_date) {
				var a = moment().startOf('day');
				var b = moment(end_date).startOf('day');
				return b.diff(a, 'days');  
			},
			diffWeeksAndDays(end_date) {
				var a = moment().startOf('day');
				var b = moment(end_date).startOf('day');

				var diff = moment.duration(b.diff(a));

				return diff.asDays() > 7 ? `${this.$tc('weeks', Math.floor(diff.asWeeks()))} ${diff.days()%7 != 0 ? this.$tc('days', diff.days()%7): ''}` : this.$tc('days', diff.days()%7) ;  
			},
			diffDaysFrom(update_date){
				var a = moment(update_date).startOf('day');
				var b = moment().startOf('day');
				return b.diff(a, 'days');  
			},
			activateTab(tab_name){
				this.activetab = tab_name;
			},
			displayAllText() {
				this.limitedDescription = false;
			},
			showDropdown(itemId){
				if(itemId == this.hoverkeyResult){
					$('#dropdownEdit' + this.hoverkeyResult).dropdown('toggle');
					setTimeout(() => {
						this.hoverkeyResult = ''
					}, 0)
				}else{
					this.hoverkeyResult = itemId
					setTimeout(() => {
						$('#dropdownEdit' + this.hoverkeyResult).dropdown('toggle');
					}, 0)
				}

			},
			hideDropdown(){
				if(this.hoverkeyResult != ''){
					$('#dropdownEdit' + this.hoverkeyResult).dropdown('toggle');
					
					setTimeout(() => {
						this.hoverkeyResult = ''
					}, 0)
				}
			},
			checkDiffUnity(unity){
				var inlineUnits = ['€', '$', 'RON']
				return inlineUnits.includes(unity)
			},
			showEdit(id){
				$('#edit-key-result-' + id).show()
				$('#arrow-key-result-' + id).show()
			},
			hideEdit(id){
				this.hideDropdown();
				setTimeout(() => {
					$('#edit-key-result-' + id).hide()
					$('#arrow-key-result-' + id).hide()
				}, 0)
			},
			checkUrlModal(){
				if(this.$route.params.krId && this.$route.params.modalType){
					var krSelected = this.objective.key_results.find(el => el.id == this.$route.params.krId)

					if(krSelected){
						if(this.$route.params.modalType == 'actualizari'){
							this.showModalKR(parseInt(krSelected.id).toString(), krSelected.uuid, 'updates')
						}else if(this.$route.params.modalType == 'promisiuni'){
							this.showModalKR(parseInt(krSelected.id).toString(), krSelected.uuid, 'promises')
						}else if(this.$route.params.modalType == 'taskuri'){
							this.showModalKR(parseInt(krSelected.id).toString(), krSelected.uuid, 'tasks')
						}else if(this.$route.params.modalType == 'comentarii'){
							this.showModalKR(parseInt(krSelected.id).toString(), krSelected.uuid, 'comments')
						}
					}
				}
			},
			displayKeyResultCurrentValue(keyResult) {
				var text = "";
				var value = keyResult.last_key_result_log ? keyResult.last_key_result_log.value : keyResult.start_value;
				return value.includes('.00') ? parseInt(value).toString() : parseFloat(value).toString();
			},
			needsDecimal(keyResult){
				var needsDecimal = false;
				var value = keyResult.last_key_result_log ? keyResult.last_key_result_log.value : keyResult.start_value;
				if(displayType == 'short'){
					needsDecimal = parseFloat(value) % 1 != 0 ? true : false;
				}			
				return needsDecimal;
			},
			changeActiveStatus(status){
				this.activeStatus = status;
			}
		}
	};

	function getByStatus(list, status) {
		if(status == 'all'){ return list }
			
		return list.filter(item => item.status == status)
	}
	function SortByDeadline(list, type) {
			if (type == 'asc'){
				return list.sort(function(a,b){
					return moment(a.end_date) - moment(b.end_date)
					}
				)
			} else if( type == 'desc') {
				return list.sort(function(a,b){
					return moment(b.end_date) - moment(a.end_date)
					}
				)
			}
	}
</script>