<template>
	<div class="modal-tbf">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Master Goal', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>
		<div class="container-modal form-modal opacity-page" v-if="loaded">
			<div class="header-modal">
				<div class="title">
					{{ editAction ? $t('master_goal.title_edit') : $t('master_goal.title_new') }}
				</div>
				<div class="actions" >
					<v-popover class="help" :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
						<a class="btn-tbf blue only-icon help-btn" :href="helpData.link" target="_blank" v-if="helpData">
							<div class="icon"><icon-question /></div>
						</a>

						<template slot="popover">
							<div class="simple-text">{{ $t(`help_links.${helpData.type}`) }}</div>
						</template>
					</v-popover>

					<div class="btn-default-tbf close-btn" @click="closeModal"><icon-plus class="icon-close" /></div>
				</div>
			</div>

			<div class="info-content">
				<transition name="slide" mode="out-in">
					<div class="container-content-modal" v-if="activeSlide == 'main_form'">
						<div class="form-section">
							<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.master_goal.name.$error, complete: master_goal.name != '' && !$v.master_goal.name.$error, 'view-tooltip': activeTooltip == 'obj_name' }">
								<div class="label-input">
									<label>{{ $t('master_goal.name') }}*</label>
									<div v-if="errorsBe.name" class="error-msg">{{ errorsBe.name.join(" | ") }}</div>
								</div>

								<div class="input-box">
									<div class="icon-left"><icon-folder /></div>
									<div class="icon-right" v-if="master_goal.name != ''" @click.stop="master_goal.name = ''"><icon-plus class="icon-clear"/></div>
									<input type="text" :placeholder="$t('master_goal.placeholder_name')" class="input-text" v-model="master_goal.name" ref="first_input">
								</div>
							</div>

							<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.selectedMasterGoal.$error, complete: selectedMasterGoal != '', 'view-tooltip': activeTooltip == 'obj_master_goal'}">
								<div class="label-input">
									<label>{{ $t('master_goal.parent') }}</label>
									<div v-if="errorsBe.master_goal_id" class="error-msg">{{ errorsBe.master_goal_id.join(" | ") }}</div>
								</div>

								<div class="input-box">
									<div class="icon-left"><icon-master-goals /></div>
									<div class="icon-right" v-if="selectedMasterGoal != ''" @click.stop="selectedMasterGoal = ''"><icon-plus class="icon-clear"/></div>
									<multiselect 
									v-model="selectedMasterGoal"
									class="select-tags-tbf"
									:options="optionsMasterGoals"
									:allow-empty="true"
									:show-labels="false"
									track-by="id" 
									label="name"
									:max-height="200">
										<template slot="placeholder" slot-scope="props">
											<span class="text">
												{{$t('objective.placeholder_vision')}}
											</span>
										</template>
										<template slot="option" slot-scope="props">
											<div :class="'option-mg level-' + props.option.level_depth" :style="'padding-left: calc(10px * ' + props.option.level_depth + ');'">
												<div class="icon-level" v-if="props.option.level_depth != 0"><icon-level-up /></div>
												<div class="'option_ellipsis">{{ props.option.name ? props.option.name : $t('master_goal.private') }}</div>
											</div>
										</template>
										<template slot="singleLabel" slot-scope="props">
											<div class="option_ellipsis">{{ props.option.name ? props.option.name : $t('master_goal.private') }}</div>
										</template>
										<template slot="noResult">{{ $t('objective.no-results') }}</template>
										<template slot="noOptions">{{ $t('general.empty-list') }}</template>
									</multiselect>	
								</div>
							</div>

							<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{complete: managersSelected.length > 0}">
								<div class="label-input">
									<label>{{ $t('master_goal.managers') }}</label>
								</div>

								<div class="input-box bor-0">
									<div class="icon-left"><icon-people /></div>

									<div class="filter-tags dropdown-tbf">
										<div class="input-form-tags input-box" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<span class="value" v-if="managersSelected.length">{{ managersSelected.length == 1 ? (optionsManagers.find(el => el.id == managersSelected[0]).first_name + ' ' + optionsManagers.find(el => el.id == managersSelected[0]).last_name) : $t('filter_users.selected', {n: managersSelected.length}) }}</span>
											<span class="placeholder" v-else>{{ $t('master_goal.search_managers') }}</span>
										</div>
										<div class="dropdown-menu" aria-labelledby="dropdownFilter">
											<div class="filter-dropdown">
												<input type="text" v-model="searchManagers" :placeholder="$t('master_goal.search_managers_by_name')">
											</div>
											<div class="dropdown-list-checkboxes">
												<div v-for="manager in filteredManagers" 
												class="dropdown-item" 
												@click.stop="selectManager(manager)" 
												v-bind:class="{active: managersSelected.includes(manager.id)}">
													<div class="checkmark"></div>
													<span class="text">{{ manager.first_name + ' ' + manager.last_name }}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.selectedPrivacy.$error, complete: selectedPrivacy != '' && !$v.selectedPrivacy.$error, 'view-tooltip': activeTooltip == 'obj_privacy'}">
								<div class="label-input">
									<label>{{ $t('objective.privacy_type') }}</label>
								</div>

								<div class="input-box bor-0">
									<div class="privacy-objectives">
										<div class="item-privacy" v-for="option in optionsPrivacy" @click="selectedPrivacy = option.value" v-bind:class="{active: selectedPrivacy == option.value}">
											<icon-public v-if="option.key == 'public'"/>
											<icon-privat v-else-if="option.key == 'private'"/>
											<icon-personal v-else/>
											<div class="name">{{option.name}}</div>
										</div>
									</div>
								</div>
							</div>

							<div class="input-group-tbf" v-bind:class="{has_error: $v.master_goal.description.$error, complete: master_goal.description != '' && !$v.master_goal.description.$error, 'view-tooltip': activeTooltip == 'obj_description'}">
								<div class="label-input">
									<label>{{ $t('master_goal.description') }}*</label>
									<div class="info-input" @click="activeTooltip == 'obj_description' ? activeTooltip = '' : activeTooltip = 'obj_description'">
										<icon-circle-check class="icon-check" v-if="master_goal.description != '' && !$v.master_goal.description.$error" />
									</div>
								</div>
								<div class="input-box">
									<textarea class="input-textarea" :placeholder="$t('master_goal.placeholder_description')" v-model="master_goal.description" v-autosize  rows="3"></textarea>
								</div>
							</div>

							<div class="submit-form">
								<button class="button-tbf-blue button-submit" @click="saveModifications" id="button-submit-mastergoal">
									<div class="loader"></div>
									<div class="text">{{ editAction ? $t('master_goal.edit_button') : $t('master_goal.save_button') }}</div>
								</button>
							</div>
						</div>
					</div>
				</transition>
			</div>

			<div class="submodal-container" v-if="subModalShow">
				<div class="overlay-submodal"></div>
				<confirm-close v-if="show_subm_modal.confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose"></confirm-close>
			</div>
		</div>
		<div class="container-modal form-modal" v-else>
			<div class="header-modal">
				<div class="title">
					<div class="placeholder-loader" style="height: 27px; width: 100px;"></div>
				</div>
				<div class="actions">
					<div class="placeholder-loader" style="height: 35px; width: 35px;"></div>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>
						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>
						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>
						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>
						<div class="input-group-tbf">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 84px;"></div>
						</div>


						<div class="submit-form">
							<div class="placeholder-loader" style="width: 250px; height: 40px;"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconPlus from "../Icons/Plus"
	import IconInfo from "../Icons/Info"
	import IconFolder from "../Icons/Folder"
	import IconArrow from "../Icons/Arrow"
	import IconPeople from "../Icons/People"
	import IconCircleCheck from "../Icons/CircleCheck"
	import IconMasterGoals from "../Icons/MasterGoals"
	import IconLevelUp from "../Icons/LevelUp"
	import ConfirmClose from "../SubModals/ConfirmClose"
	import IconPublic from "../Icons/Public"
	import IconPrivat from "../Icons/Privat"
	import IconQuestion from "../Icons/Question"
	import { required, requiredIf } from 'vuelidate/lib/validators'

	export default {
		data() {
			return {
				loaded: false,
				editAction: false,
				activeTooltip: '',
				totalDaysRange: 0,
				activeSlide: 'main_form',
				master_goal: {
					name: '',
					description: ''
				},
				parentVision: '',
				selectedMasterGoal: '',
				optionsMasterGoals: [],
				subModalShow: false,
				show_subm_modal: {
					confirm_close: false,
					date_range: false
				},
				managersSelected: [],
				optionsManagers: [],
				searchManagers: '',
			  	selectedPrivacy: 0,
			  	optionsPrivacy: [{id: 1, name: this.$t('general.public'), key: 'public', value: 0}, {id: 2, name: this.$t('general.private'), key: 'private', value: 1}],
				arrayTranslations: [
					'master_goal.title_edit',
					'master_goal.title_new',
					'master_goal.name',
					'master_goal.placeholder_name',
					'master_goal.parent',
					'objective.placeholder_vision',
					'master_goal.private',
					'objective.no-results',
					'general.empty-list',
					'master_goal.managers',
					'filter_users.selected',
					'master_goal.search_managers',
					'master_goal.search_managers_by_name',
					'objective.privacy_type',
					'master_goal.description',
					'master_goal.placeholder_description',
					'master_goal.edit_button',
					'master_goal.save_button',
					'btn-submit.loading',
					'btn-submit.success',
					'btn-submit.error',
					'master_goal.edit_button',
					'master_goal.save_button',
				],
				errorsBe: {},
				helpData: this.$store.getters['help_links/currentLink']('objective_groups')
			};
		},
		props:{
			model: String,
			requiredData: Object,
			userData: Object,
			fromPage: String
		},
		computed: {
			filteredManagers(){
				return getByKeywordManagers(this.optionsManagers, this.searchManagers)
			}
		},
		components: {
			IconPlus,
			IconInfo,
			IconFolder,
			IconArrow,
			IconPeople,
			IconCircleCheck,
			ConfirmClose,
			IconMasterGoals,
			IconLevelUp,
			IconPublic,
			IconPrivat,
			IconQuestion
		},
		validations: {
			master_goal: {
				name: {required},
				description: {required}
			},
			selectedPrivacy: {required},
			selectedMasterGoal: {
				required: requiredIf(function () {
					return this.$auth.user().is_manager
				})
			}
		},
		async mounted() {
			if(this.model == ''){
				this.editAction = false
			}else{
				await this.getMasterGoalData()
				this.editAction = true
			}

			if(!(Object.keys(this.requiredData).length === 0 && this.requiredData.constructor === Object)){
				this.parentVision = this.requiredData.id
			}

			await this.getManagers()
			await this.getMasterGoals()
			
			this.$root.$on("confirm_close", () => {
				if(this.checkInputs()){
					this.subModalShow = true
					this.show_subm_modal = {
	                	date_range: false,
	                	confirm_close: true
		            }
					setTimeout(() => {
	                	$('.submodal-container').addClass('active');
	                	setTimeout(() => {
	                		$('#submodal_confirm_close').addClass('active');
						}, 200);
	                }, 0);
				}else{
					this.$emit("toggle_modal");
				}
            });
			if(this.fromPage == 'objective'){
            	this.$root.$emit('visibilityHiddenObjective', true);
			}
            
		},
		methods: {
			async getManagers(){
				await axios.get(`/instances/${this.$auth.user().instance_id}/filter`, { params: { users: true, manager_master_goals: true } })
				.then(({data}) => {
					this.optionsManagers = Object.values(data.data.users)
				})
			},
			async getMasterGoalData(){
				await axios.get('/master-goals/'+ this.model +'/edit').then(({data}) => {
					this.master_goal = data.data

					if(this.master_goal.manager_users.length){
						this.master_goal.manager_users.forEach(el => {
							this.managersSelected.push(el.id)
						})
					}

					this.selectedPrivacy = this.master_goal.is_private
				})
			},
			async getMasterGoals(){
				var linkGetMasterGoals = this.editAction ? (!this.master_goal.related_to ? ('/master-goals?parent_id=' + this.master_goal.id) : '/master-goals') : '/master-goals'

				await axios.get(linkGetMasterGoals)
				.then(({data}) => {
					this.optionsMasterGoals = data.data ? data.data : []

					if(this.editAction){
						this.optionsMasterGoals.find(el => el.id == this.master_goal.id).$isDisabled = true

						if(this.master_goal.master_goal_id){
							this.selectedMasterGoal = this.optionsMasterGoals.find(el => el.id == this.master_goal.master_goal_id);
						}
					}

					if(this.$auth.user().is_manager){
						var optionsDisabledManager = this.optionsMasterGoals.filter(el => el.disable_for_manager == true)

						optionsDisabledManager.forEach(element => {
							this.optionsMasterGoals.find(el => el.id == element.id).$isDisabled = true
						}) 
					}

					if(this.parentVision){
						this.selectedMasterGoal = this.optionsMasterGoals.find(el => el.id == this.parentVision);
					}
				})
				.then(() => {
					setTimeout(()=>{
						this.loaded = true
						setTimeout(() => {
        					this.$refs.first_input.focus()
							$('.opacity-page').addClass('show')
						}, 0)
					}, 300)
				})
			},			
			changeActiveSlide(value){
				this.activeSlide = value
			},
			closeModal(){
				if(this.checkInputs()){
					this.subModalShow = true
					this.show_subm_modal = {
	                	date_range: false,
	                	confirm_close: true
		            }
					setTimeout(() => {
	                	$('.submodal-container').addClass('active');
	                	setTimeout(() => {
	                		$('#submodal_confirm_close').addClass('active');
						}, 200);
	                }, 0);
				}else{
					if(this.fromPage == 'objective'){
		            	this.$root.$emit('visibilityHiddenObjective', false);
					}
					this.$emit("toggle_modal");
				}
			},
			closeModalConfirmed(){
				this.$emit("toggle_modal");
			},
			cancelClose(){
				$("#submodal_confirm_close").removeClass("active")
				setTimeout(() => {
					$('.submodal-container').removeClass('active')
					setTimeout(() => {
						this.subModalShow = false;
						this.show_subm_modal.confirm_close = false
					}, 200);
				}, 200);
			},
			saveModifications(e){
				$('#button-submit-mastergoal').attr('disabled', 'disabled')
				
				this.$v.$touch()
				if(!this.$v.$invalid){
					var btnSubmit = $('#button-submit-mastergoal')
					var btnSubmitLoader = $('#button-submit-mastergoal .loader')
					var btnSubmitText = $('#button-submit-mastergoal .text')

					btnSubmit.addClass('loading')
					btnSubmitLoader.addClass('onProgress')
					btnSubmitText.html(this.$t('btn-submit.loading'))

					if(this.editAction){
						this.updatemaster_goal(e)
					}else{
						this.storemaster_goal(e)
					}
				}else{
					$('#button-submit-mastergoal').attr('disabled', false)
				}
			},
			storemaster_goal(e){
				var btnSubmit = $('#button-submit-mastergoal')
				var btnSubmitLoader = $('#button-submit-mastergoal .loader')
				var btnSubmitText = $('#button-submit-mastergoal .text')

				axios.post('/master-goals/store', {
					name: this.master_goal.name,
					description: this.master_goal.description,
					master_goal_id: this.selectedMasterGoal ? this.selectedMasterGoal.id : '',
					manager_users: this.managersSelected,
					is_private: this.selectedPrivacy
				}).then(({data}) => {
					btnSubmitLoader.addClass('finish')
					this.refreshPage(data.data)
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.success'))
						btnSubmit.addClass('completed')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('completed')
							this.$emit("toggle_modal");
						}, 1000)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.addClass('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.error'))
						btnSubmit.addClass('error')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('error')
							btnSubmitText.html(this.editAction ? this.$t('master_goal.edit_button') : this.$t('master_goal.save_button'))
						}, 1000)
					}, 300)
				}).finally(() => {
					setTimeout(()=>{
						btnSubmit.attr('disabled', false)
					}, 1500)
				})
			},
			updatemaster_goal(e){
				var btnSubmit = $('#button-submit-mastergoal')
				var btnSubmitLoader = $('#button-submit-mastergoal .loader')
				var btnSubmitText = $('#button-submit-mastergoal .text')

				axios.patch('/master-goals/' + this.master_goal.slug, {
					name: this.master_goal.name,
					description: this.master_goal.description,
					master_goal_id: this.selectedMasterGoal ? this.selectedMasterGoal.id : '',
					manager_users: this.managersSelected,
					is_private: this.selectedPrivacy
				}).then(({data}) => {
					btnSubmitLoader.addClass('finish')
					if(this.$route.name == 'show-master-goal'){
						this.$root.$emit('refreshMasterGoalTree', data.data)
					}else if(this.$route.name == 'master-goals'){
						this.$root.$emit("refreshMasterGoalsList");
					}
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.success'))
						btnSubmit.addClass('completed')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('completed')
							this.$emit("toggle_modal");
						}, 1000)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.addClass('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.error'))
						btnSubmit.addClass('error')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('error')
							btnSubmitText.html(this.editAction ? this.$t('master_goal.edit_button') : this.$t('master_goal.save_button'))
						}, 1000)
					}, 300)
				}).finally(() => {
					setTimeout(()=>{
						btnSubmit.attr('disabled', false)
					}, 1500)
				})
			},
			checkInputs(){
				var notEmpty = false

				if(this.master_goal.name != '' 
					|| this.master_goal.description != '' 
				){
					notEmpty = true
				}

				return notEmpty
			},
			showSubModal(type){
				this.subModalShow = true
				this.show_subm_modal[type] = true
				setTimeout(() => {
                	$('.submodal-container').addClass('active');
                	setTimeout(() => {
                		$('#submodal_' + type).addClass('active');
					}, 200);
                }, 0);
			},
			closeSubModal(type){
				$("#submodal_" + type).removeClass("active")
				setTimeout(() => {
					$('.submodal-container').removeClass('active')
					setTimeout(() => {
						this.subModalShow = false;
						this.show_subm_modal[type] = false
					}, 200);
				}, 200);
			},
			refreshPage(master_goal_detail){
				switch (this.fromPage) {
					case 'master_goals':
						this.$root.$emit('refreshMasterGoalsList')
						break;
					case 'tree':
						this.$root.$emit('refreshMasterGoalTree')
						break;
					case 'objective':
						this.$root.$emit('refreshMasterGoalTreeForObjectiveModal',master_goal_detail)
						break;
				}
			},
			selectManager(item){
				if(this.managersSelected.includes(parseInt(item.id))){
					this.managersSelected.splice(this.managersSelected.indexOf(parseInt(item.id)), 1)
				}else{
					this.managersSelected.push(parseInt(item.id))
				}
				this.searchManagers = ''
			}
		}
	};

	function getByKeywordManagers(list, keyword) {
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>