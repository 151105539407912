<template>
	<div class="template-tbfdigital">
		<sidebar v-if="$resize && $mq.above(781)"/>
        <sidebar-settings v-if="$resize && $mq.above(781)"/>
		<div class="container-tbf">
			<navbar class="row-space-tbf"/>
			<div class="container-content">
				<slot></slot>
			</div>
            <sidebar-mobile v-if="$resize && $mq.below(780)"/>
		</div>
        <notifications/>

        <div class="overflow-modal" v-if="show_overflow">
            <div class="bg-modals" @click="closeModals()"></div>

            <modal-delete id="modal_delete" v-if="show_modal.delete" :model="data.delete" :type="typeDelete" :from="fromDelete" @close_modal="closeModal('delete')" />
            <modal-cancel-subscription id="modal_cancel_subscription" v-if="show_modal.cancel_subscription" @close_modal="closeModal('cancel_subscription')" />
            <modal-renew-subscription id="modal_renew_subscription" v-if="show_modal.renew_subscription" @close_modal="closeModal('renew_subscription')" />
            <modal-change-plan-confirmation id="modal_change_plan_confirmation" v-if="show_modal.change_plan_confirmation" :data="data.change_plan_confirmation" @close_modal="closeModal('change_plan_confirmation')"/>
            <modal-change-subscription id="modal_change_subscription" v-if="show_modal.change_subscription" @close_modal="closeModal('change_subscription')"/>
            <modal-notifications id="modal_notifications" v-if="show_modal.notifications" @close_modal="closeModal('notifications')"/>
            <modal-settings-mobile id="modal_settings_mobile" v-if="show_modal.settings_mobile" @close_modal="closeModal('settings_mobile')"/>
            <modal-contact id="modal_contact" v-if="show_modal.contact" @close_modal="closeModal('contact')"/>
            <modal-webinar-tehnic id="modal_webinar_tbf" v-if="show_modal.webinar_tbf" @close_modal="closeModal('webinar_tbf')"/>
			<modal-procedure-check id="modal_procedure_check" v-if="show_modal.procedure_check" @close_modal="closeModal('procedure_check')" />

            <modal-mission-vision id="modal_mission_vision" v-if="show_modal.mission_vision" @close_modal="closeModal('mission_vision')" />
            <modal-payment-error id="modal_payment_error" v-if="show_modal.payment_error" @close_modal="closeModal('payment_error')"/>
        </div>
	</div>
</template>

<script>
    import Sidebar from "../General/Sidebar"
    import SidebarSettings from '../General/SidebarSettings.vue'
    import Navbar from '../General/Navbar.vue'
    import ModalChangePlanConfirmation from "../Modals/ChangePlanConfirmation"
    import ModalDelete from "../Modals/Delete"
    import ModalCancelSubscription from "../Modals/CancelSubscription"
    import ModalRenewSubscription from "../Modals/RenewSubscription"
    import ModalNotifications from "../Modals/Notifications"
    import ModalChangeSubscription from "../Modals/ChangeSubscription"
    import SidebarMobile from "../General/SidebarMobile"
    import ModalSettingsMobile from "../General/SidebarSettingsMobile"
    import ModalContact from "../Modals/Contact"
    import ModalWebinarTehnic from "../Modals/WebinarTehnic"
    import ModalMissionVision from "../Modals/MissionVision"
    import ModalPaymentError from "../Modals/PaymentError"
    import ModalProcedureCheck from "../Modals/ProcedureCheck"
	import Notifications from "../General/Notifications"

    export default {
        data() {
            return {
                show_overflow: false,
                show_modal: {
                    change_plan_confirmation: false,
                    cancel_subscription: false,
                    delete: false,
                    notifications: false,
                    renew_subscription: false,
                    contact: false,
                    webinar_tbf: false,
                    mission_vision: false,
                    payment_error: false,
                    procedure_check: false
                },
                data: {
                    change_plan_confirmation: {},
                    delete: {},
                },
                typeDelete: false,
				fromDelete: false,
            }
        },
        components: {
            Sidebar,
            SidebarSettings,
            Navbar,
            ModalChangePlanConfirmation,
            ModalDelete,
            ModalCancelSubscription,
            ModalNotifications,
            ModalRenewSubscription,
            ModalChangeSubscription,
            SidebarMobile,
            ModalContact,
            ModalSettingsMobile,
            ModalWebinarTehnic,
            ModalPaymentError,
            ModalMissionVision,
            ModalProcedureCheck,
            Notifications
        },
        mounted(){
            this.$root.$on("open_modal", (type, data = false) => {
                this.active_modal = type
                this.data[type] = data ? data : {}

                setTimeout(() => {
                    this.show_overflow = true
                    this.show_modal[type] = true
                    setTimeout(() => {
                        $('.overflow-modal').addClass('show')
                        $("#modal_" + type).addClass("active")
                    }, 0);
                }, 0);
            })

            this.$root.$on('open_modal_v2', (type, data = false, typeDelete = false, fromDelete = false) => {
                this.active_modal = type
				this.data[type] = data ? data : {}
				this.typeDelete = typeDelete ? typeDelete : false
				this.fromDelete = fromDelete ? fromDelete : false
                setTimeout(() => {
                    this.show_overflow = true
					this.show_modal[type] = true
                    setTimeout(() => {
                    	$('.overflow-modal').addClass('show');
            			$("#modal_" + type).addClass("active");
                    }, 0);
                }, 0);
            });
        },
        methods: {
            closeModals(){
                this.closeModal()
            },
            closeModal(type = false, closeOverflow = true){
                var target_modal = type ? type : this.active_modal
                $("#modal_" + target_modal).removeClass("active")

                setTimeout(() => {
                    this.show_modal[target_modal] = false

                    if(closeOverflow){
                        $('.overflow-modal').removeClass('show')
                        setTimeout(() => {
                            this.show_overflow = false;
                        }, 200)
                    }
                }, 200);
            },
        }
    }
</script>